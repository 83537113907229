import { Env } from "./environment.type";

export const environment: Env = {
    production: false,
    apiUrl: "https://api.seyerli.lemsainnovation.com",
    firebase: {
      apiKey: "AIzaSyBhF326jP5adP0dyptyd8DapLK00R2Zzi4",
      authDomain: "seyerli-dev.firebaseapp.com",
      projectId: "seyerli-dev",
      storageBucket: "seyerli-dev.firebasestorage.app",
      messagingSenderId: "448678439226",
      appId: "1:448678439226:web:784a10f54609dc287d0803",
      measurementId: "G-9X1ED4433R"
  }
};
